<template>
  <page-header :title="siteName" :breadcrumbs="pathMatch">
    <div class="page-header-title">
      <span>{{ siteName }}</span>
      <div>
        <el-button type="primary" size="small" @click="back">返回</el-button>
      </div>
    </div>
    <el-row v-if="data.length > 0">
      <el-col :md="6" :sm="12" v-for="(item, index) in data" :key="item.id">
        <div class="tank-box">
          <div class="tank-icon">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看"
              placement="top"
            >
              <i class="el-icon-view" @click="handleViewTank(item)"></i>
            </el-tooltip>
          </div>
          <tank-box :data="item" :index="index" />
        </div>
      </el-col>
    </el-row>
    <div v-else>
      <div style="color: #999;text-align: center;">没有油罐数据！</div>
    </div>

    <el-dialog
      title="详情"
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="RowInfo" label-width="115px" disabled>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属加油站" prop="siteId">
              <el-input
                style="width:100%"
                v-model="siteName"
                placeholder="请输入所属加油站"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="num" label="油罐号">
              <el-input
                style="width:100%"
                v-model="RowInfo.num"
                placeholder="请输入油罐号"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="meterNum" label="液位仪编号">
              <el-input
                style="width:100%"
                v-model="RowInfo.meterNum"
                placeholder="请输入液位仪编号"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankHeight" label="油罐高度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankHeight"
                placeholder="请输入油罐高度(m)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankLength" label="油罐长度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankLength"
                placeholder="请输入油罐长度(m)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankHead" label="封头长度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankHead"
                placeholder="请输入封头长度(m)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="oilCapacity" label="最大容量(L)">
              <el-input
                style="width:100%"
                v-model="RowInfo.oilCapacity"
                placeholder="请输入最大容量(L)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="type" label="油品">
              <el-input
                style="width:100%"
                v-model="RowInfo.type"
                placeholder="请输入油品"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="inputTime" label="投入时间">
              <el-date-picker
                style="width:100%"
                v-model="RowInfo.inputTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-for="(item, index) in tankOptions" :key="index">
            <el-form-item :label="item.name" :prop="'' + item.id">
              <el-select
                v-model="RowInfo[item.id]"
                multiple
                style="width:100%"
                placeholder="请选择油枪"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="(itex, indx) in item.option"
                  :key="indx"
                  :label="itex.content"
                  :value="itex.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import TankBox from '../gauge_info/components/tank';
import {
  GetOilTank,
  getTankerAll,
  getPortGunAll
} from '@/api/level_gauge_info';
// import EleForm from 'vue-ele-form';

export default {
  components: {
    PageHeader,
    TankBox
    // EleForm
  },
  data() {
    return {
      data: [],
      siteName: this.$store.state.user.depart_name,
      site_id: this.$store.state.user.site_id,
      visible: false,
      RowInfo: {},
      tankOptions: [],
      tankId: []
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      GetOilTank({ siteId: this.site_id }).then(res => {
        if (res.code === 0) {
          this.data = res.data;
        }
      });
    },
    // 查看
    handleViewTank(val) {
      this.visible = true;
      this.RowInfo = {
        ...val
      };
      this.GetAllTankerList(val.type);
    },
    async GetAllTankerList(type) {
      this.tankOptions = [];
      this.tankId = [];
      let params = {
        id: this.site_id,
        type: type
      };
      let result;
      result = await getTankerAll(params);
      if (result.code === 0) {
        result.data.map(async item => {
          let params = {
            id: item.id,
            type: type
          };
          this.tankId.push(item.id);
          let portResult;
          portResult = await getPortGunAll(params);
          let options = [],
            option = [];
          if (portResult.code === 0) {
            option = portResult.data;
            options.push({
              id: item.id,
              name: item.name,
              option: option
            });
            this.tankOptions.push(...options);
            this.tankOptions.sort((a, b) => {
              return a.name.charAt(0) - b.name.charAt(0);
            });
          }
        });
        this.RowInfo.tankerGun.map(item => {
          this.RowInfo[item[0]] = item.slice(1);
        });
      }
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.page-header-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tank-box {
  position: relative;
  padding-top: 18px;
}
.tank-box:hover .tank-icon {
  display: block;
}
.tank-box .tank-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding-right: 25px;
}
.tank-box .tank-icon i {
  padding: 10px 5px;
  cursor: pointer;
  color: #000;
}
.tank-box .tank-icon i:hover {
  color: rgba(0, 0, 0, 0.6);
}
.tank-box .tank-icon i:last-child {
  color: rgb(243, 94, 74);
}
</style>
